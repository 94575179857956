import { FC, PropsWithChildren, ReactNode } from 'react';
export const If: FC<PropsWithChildren<{
  condition: boolean;
  otherwise?: ReactNode;
}>> = ({
  condition,
  children,
  otherwise
}) => {
  return condition ? children : otherwise ?? null;
};