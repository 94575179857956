import { AdSize, ViewportSize } from '@/core/ad-config';
import { useGooglePublisherTags } from '@/providers/google-publisher-tags-provider';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';

export function useAdSetup({
  adUnitPath,
  divId,
  sizeMappings,
  enabled,
}: {
  adUnitPath: string;
  divId: string;
  sizeMappings: Array<{ viewport: ViewportSize; sizes: AdSize[] }>;
  enabled: boolean;
}) {
  const { googletag, isReady } = useGooglePublisherTags();
  const [slot, setSlot] = useState<googletag.Slot | null>(null);
  const adUnitId = getAdUnitIdFromPath(adUnitPath);

  useEffect(() => {
    if (enabled && googletag && isReady) {
      let slot: googletag.Slot | null = null;
      const orderedSizeMappings = orderBy(sizeMappings, (x) => x.viewport[0], 'desc');

      googletag.cmd.push(() => {
        const allSizes = uniqBy(
          orderBy(
            sizeMappings.flatMap((item) => item.sizes),
            [(x) => x[0], (x) => x[1]],
            ['desc', 'desc']
          ),
          (size) => size.join('x')
        );
        const sizeMapping: googletag.SizeMappingBuilder = googletag.sizeMapping();
        slot = googletag.defineSlot(adUnitPath, allSizes, divId);

        if (slot) {
          slot.addService(googletag.pubads());
          googletag.display(divId);
        }

        for (const mapping of orderedSizeMappings) {
          const { viewport, sizes } = mapping;
          sizeMapping.addSize(viewport, sizes);
        }

        const mapping = sizeMapping.build();

        if (slot && mapping) {
          slot.defineSizeMapping(mapping).addService(googletag.pubads());
          googletag.pubads().refresh([slot], {
            changeCorrelator: false,
          });
        }

        setSlot(slot);
      });

      return () => {
        googletag?.cmd.push(() => {
          if (slot) {
            googletag.destroySlots([slot]);
            slot = null;
          }
        });
      };
    }
  }, [enabled, googletag, adUnitPath, sizeMappings, divId, adUnitId, isReady]);

  return { slot };
}

export function useOutOfPageAdSetup({
  adUnitPath,
  format,
}: {
  adUnitPath: string;
  format: 'interstitial' | 'bottom-anchor';
}) {
  const { googletag, isReady } = useGooglePublisherTags();
  const [slot, setSlot] = useState<googletag.Slot | null>(null);
  const adUnitId = getAdUnitIdFromPath(adUnitPath);

  useEffect(() => {
    if (googletag && isReady) {
      let slot: googletag.Slot | null = null;

      googletag.cmd.push(() => {
        slot = googletag.defineOutOfPageSlot(
          adUnitPath,
          format === 'bottom-anchor'
            ? googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
            : googletag.enums.OutOfPageFormat.INTERSTITIAL
        );

        if (slot) {
          slot.addService(googletag.pubads());
          googletag.display(slot);
        }

        setSlot(slot);
      });

      return () => {
        googletag?.cmd.push(() => {
          if (slot) {
            googletag.destroySlots([slot]);
            slot = null;
          }
        });
      };
    }
  }, [googletag, adUnitPath, adUnitId, isReady, format]);

  return { slot };
}

function getAdUnitIdFromPath(adUnitPath: string) {
  return adUnitPath.split('/').pop() as string;
}
