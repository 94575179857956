import { useGooglePublisherTags } from '@/providers/google-publisher-tags-provider';
import debounce from 'lodash/debounce';
import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export function useRefreshAdSlot({
  slot,
  enabled,
  resizeEnabled,
}: {
  slot: googletag.Slot | null;
  enabled: boolean;
  resizeEnabled?: boolean;
}) {
  const pathname = usePathname();
  const { googletag, refreshInterval } = useGooglePublisherTags();
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const inViewRef = useRef(inView);
  inViewRef.current = inView;

  // Refresh ad content every 30 seconds
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (!slot) {
      return;
    }

    const scheduleRefresh = () => {
      clearTimeout(timeoutId);

      if (enabled && refreshInterval) {
        timeoutId = setTimeout(() => {
          googletag?.cmd.push(() => {
            // Only refresh if the ad is in view
            if (inViewRef.current) {
              googletag.pubads().refresh([slot], {
                changeCorrelator: false,
              });
            }
            scheduleRefresh();
          });
        }, refreshInterval);
      }
    };
    scheduleRefresh();

    const handleResize = debounce(() => {
      if (resizeEnabled) {
        googletag?.pubads().refresh([slot], {
          changeCorrelator: false,
        });
      }
      scheduleRefresh();
    }, 250);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, [googletag, pathname, refreshInterval, enabled, slot, resizeEnabled]);

  return { ref };
}
