'use client';

import { useOutOfPageAdSetup } from '@/components/ads/use-ad-setup';
import { useGooglePublisherTags } from '@/providers/google-publisher-tags-provider';
import { AdvertisingTargetingMap } from '@/types/advertising';
import { If } from '@spikemark/ui/conditionals/if';
import { FC } from 'react';
import { useAdTargeting } from './use-ad-targeting';
export type OutOfPageAdSlotProps = {
  adUnitPath: string;
  targeting?: AdvertisingTargetingMap;
  location?: string;
  format: 'interstitial' | 'bottom-anchor';
};
const OutOfPageAdSlotBody: FC<OutOfPageAdSlotProps> = ({
  adUnitPath,
  targeting,
  format,
  location // TODO: Leave this here for now, but how should we handle location?
}) => {
  const {
    slot
  } = useOutOfPageAdSetup({
    adUnitPath,
    format
  });
  useAdTargeting({
    slot,
    targeting
  });
  return null;
};
export const OutOfPageAdSlot: FC<OutOfPageAdSlotProps> = props => {
  const {
    isAdUnitEnabled
  } = useGooglePublisherTags();
  return <If condition={isAdUnitEnabled(props.adUnitPath)} data-sentry-element="If" data-sentry-component="OutOfPageAdSlot" data-sentry-source-file="out-of-page-ad-slot.tsx">
      <OutOfPageAdSlotBody {...props} data-sentry-element="OutOfPageAdSlotBody" data-sentry-source-file="out-of-page-ad-slot.tsx" />
    </If>;
};