import { AdvertisingTargetingMap } from '@/types/advertising';
import { useEffect } from 'react';

export function useAdTargeting({
  slot,
  targeting,
}: {
  slot: googletag.Slot | null;
  targeting?: AdvertisingTargetingMap;
}) {
  useEffect(() => {
    if (slot && targeting) {
      googletag.cmd.push(() => {
        Object.entries(targeting).forEach(([key, value]) => {
          if (value) {
            slot?.setTargeting(key, value);
          }
        });
      });
    }
  }, [slot, targeting]);
}
