/* tslint:disable */
/* eslint-disable */
/**
 * Clippd Spikemark API
 * # Overview Spikemark provides tournament scoring, real-time leader boards, detailed statistics, in-depth analytics and media through a platform that  includes easy-to-navigate screens allowing users to keep track of their favorite teams and players.  Clippd is an AI-focussed technology business building a connected future for golfers and coaches who have a passion for performance.  At our core is a universal platform that aggregates and elevates all the performance data created by millions of golfers and coaches every day.   This documentation provides the \"Spikemark API\", powered by the Clippd platform. The purpose of the API is to provide advanced analytics and  integration for rankings for collegiate golf.  In addition to this documentation, we also provide an [OpenAPI](https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.1.md)  specification describing the Clippd API Specification in [YAML](openapi.yaml) and [JSON](openapi.json) formats.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@clippd.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Object representing the data transfer of the Tournament
 * @export
 * @interface Tournament
 */
export interface Tournament {
  /**
   * Unique identifier of the tournament
   * @type {string}
   * @memberof Tournament
   */
  tournamentId: string;
  /**
   * Name of the tournament
   * @type {string}
   * @memberof Tournament
   */
  tournamentName: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  gender: TournamentGenderEnum;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  division: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  eventType: TournamentEventTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  endDate: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  tournamentLogo?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  venue?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  venueId?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  course?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  dataSource: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  inviteType: TournamentInviteTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  hostId: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  hostType?: TournamentHostTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  hostName?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  hostBoardName?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  schoolLogo?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  schoolLogoThumbnail?: string;
  /**
   *
   * @type {boolean}
   * @memberof Tournament
   */
  hasResults?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Tournament
   */
  isComplete?: boolean;
  /**
   * The planned rounds of play entered by the host.
   * @type {number}
   * @memberof Tournament
   */
  plannedRounds?: number;
  /**
   * Rounds for which we have some data in the system.
   * @type {number}
   * @memberof Tournament
   */
  numRounds?: number;
  /**
   *
   * @type {number}
   * @memberof Tournament
   */
  numTeamPlayers?: number;
  /**
   *
   * @type {number}
   * @memberof Tournament
   */
  numTeamScoring?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Tournament
   */
  competingSchools?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  sponsor?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  tournamentIntegrationId?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  tournamentPrivacy?: TournamentTournamentPrivacyEnum;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  tournamentLevel?: TournamentTournamentLevelEnum;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  leaderboardMessage?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  timezone?: string;
  /**
   *
   * @type {string}
   * @memberof Tournament
   */
  shareCode?: string;
  /**
   * A list of round statuses.
   * @type {Array>}
   * @memberof TournamentRequest
   */
  roundStatuses?: Array<{
    roundId: string;
    status: 'CANCELLED' | 'ACTIVE';
  }> | null;
}

/**
 * @export
 */
export const TournamentGenderEnum = {
  Men: 'Men',
  Women: 'Women',
  CombinedMenAndWomen: 'Combined Men and Women',
} as const;
export type TournamentGenderEnum = (typeof TournamentGenderEnum)[keyof typeof TournamentGenderEnum];

/**
 * @export
 */
export const TournamentEventTypeEnum = {
  StrokePlay: 'Stroke Play',
  MatchPlay: 'Match Play',
  MedalMatchPlay: 'Medal Match Play',
} as const;
export type TournamentEventTypeEnum =
  (typeof TournamentEventTypeEnum)[keyof typeof TournamentEventTypeEnum];

/**
 * @export
 */
export const TournamentInviteTypeEnum = {
  Team: 'team',
  Individual: 'individual',
} as const;
export type TournamentInviteTypeEnum =
  (typeof TournamentInviteTypeEnum)[keyof typeof TournamentInviteTypeEnum];

/**
 * @export
 */
export const TournamentHostTypeEnum = {
  School: 'SCHOOL',
  Division: 'DIVISION',
  Region: 'REGION',
  Conference: 'CONFERENCE',
} as const;
export type TournamentHostTypeEnum =
  (typeof TournamentHostTypeEnum)[keyof typeof TournamentHostTypeEnum];

/**
 * @export
 */
export const TournamentTournamentPrivacyEnum = {
  Public: 'PUBLIC',
  Private: 'PRIVATE',
  ShareCode: 'SHARE_CODE',
} as const;
export type TournamentTournamentPrivacyEnum =
  (typeof TournamentTournamentPrivacyEnum)[keyof typeof TournamentTournamentPrivacyEnum];

/**
 * @export
 */
export const TournamentTournamentLevelEnum = {
  Tournament: 'TOURNAMENT',
  Qualifying: 'QUALIFYING',
  Practice: 'PRACTICE',
} as const;
export type TournamentTournamentLevelEnum =
  (typeof TournamentTournamentLevelEnum)[keyof typeof TournamentTournamentLevelEnum];

/**
 * Check if a given object implements the Tournament interface.
 */
export function instanceOfTournament(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'tournamentId' in value;
  isInstance = isInstance && 'tournamentName' in value;
  isInstance = isInstance && 'gender' in value;
  isInstance = isInstance && 'division' in value;
  isInstance = isInstance && 'eventType' in value;
  isInstance = isInstance && 'startDate' in value;
  isInstance = isInstance && 'endDate' in value;
  isInstance = isInstance && 'dataSource' in value;
  isInstance = isInstance && 'inviteType' in value;
  isInstance = isInstance && 'hostId' in value;

  return isInstance;
}

export function TournamentFromJSON(json: any): Tournament {
  return TournamentFromJSONTyped(json, false);
}

export function TournamentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tournament {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tournamentId: json['tournamentId'],
    tournamentName: json['tournamentName'],
    gender: json['gender'],
    division: json['division'],
    eventType: json['eventType'],
    startDate: json['startDate'],
    endDate: json['endDate'],
    tournamentLogo: !exists(json, 'tournamentLogo') ? undefined : json['tournamentLogo'],
    venue: !exists(json, 'venue') ? undefined : json['venue'],
    venueId: !exists(json, 'venueId') ? undefined : json['venueId'],
    course: !exists(json, 'course') ? undefined : json['course'],
    city: !exists(json, 'city') ? undefined : json['city'],
    state: !exists(json, 'state') ? undefined : json['state'],
    dataSource: json['dataSource'],
    inviteType: json['inviteType'],
    hostId: json['hostId'],
    hostType: !exists(json, 'hostType') ? undefined : json['hostType'],
    hostName: !exists(json, 'hostName') ? undefined : json['hostName'],
    hostBoardName: !exists(json, 'hostBoardName') ? undefined : json['hostBoardName'],
    schoolLogo: !exists(json, 'schoolLogo') ? undefined : json['schoolLogo'],
    schoolLogoThumbnail: !exists(json, 'schoolLogoThumbnail')
      ? undefined
      : json['schoolLogoThumbnail'],
    hasResults: !exists(json, 'hasResults') ? undefined : json['hasResults'],
    isComplete: !exists(json, 'isComplete') ? undefined : json['isComplete'],
    plannedRounds: !exists(json, 'plannedRounds') ? undefined : json['plannedRounds'],
    numRounds: !exists(json, 'numRounds') ? undefined : json['numRounds'],
    numTeamPlayers: !exists(json, 'numTeamPlayers') ? undefined : json['numTeamPlayers'],
    numTeamScoring: !exists(json, 'numTeamScoring') ? undefined : json['numTeamScoring'],
    competingSchools: !exists(json, 'competingSchools') ? undefined : json['competingSchools'],
    sponsor: !exists(json, 'sponsor') ? undefined : json['sponsor'],
    tournamentIntegrationId: !exists(json, 'tournamentIntegrationId')
      ? undefined
      : json['tournamentIntegrationId'],
    tournamentPrivacy: !exists(json, 'tournamentPrivacy') ? undefined : json['tournamentPrivacy'],
    tournamentLevel: !exists(json, 'tournamentLevel') ? undefined : json['tournamentLevel'],
    leaderboardMessage: !exists(json, 'leaderboardMessage')
      ? undefined
      : json['leaderboardMessage'],
    timezone: !exists(json, 'timezone') ? undefined : json['timezone'],
    shareCode: !exists(json, 'shareCode') ? undefined : json['shareCode'],
    roundStatuses: !exists(json, 'roundStatuses') ? undefined : json['roundStatuses'],
  };
}

export function TournamentToJSON(value?: Tournament | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tournamentId: value.tournamentId,
    tournamentName: value.tournamentName,
    gender: value.gender,
    division: value.division,
    eventType: value.eventType,
    startDate: value.startDate,
    endDate: value.endDate,
    tournamentLogo: value.tournamentLogo,
    venue: value.venue,
    venueId: value.venueId,
    course: value.course,
    city: value.city,
    state: value.state,
    dataSource: value.dataSource,
    inviteType: value.inviteType,
    hostId: value.hostId,
    hostType: value.hostType,
    hostName: value.hostName,
    hostBoardName: value.hostBoardName,
    schoolLogo: value.schoolLogo,
    schoolLogoThumbnail: value.schoolLogoThumbnail,
    hasResults: value.hasResults,
    isComplete: value.isComplete,
    plannedRounds: value.plannedRounds,
    numRounds: value.numRounds,
    numTeamPlayers: value.numTeamPlayers,
    numTeamScoring: value.numTeamScoring,
    competingSchools: value.competingSchools,
    sponsor: value.sponsor,
    tournamentIntegrationId: value.tournamentIntegrationId,
    tournamentPrivacy: value.tournamentPrivacy,
    tournamentLevel: value.tournamentLevel,
    leaderboardMessage: value.leaderboardMessage,
    timezone: value.timezone,
    shareCode: value.shareCode,
    roundStatuses: value.roundStatuses,
  };
}
