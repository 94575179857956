import {
  ElasticTournament,
  TournamentEventTypeEnum,
  TournamentRequestTournamentIntegrationIdEnum,
  TournamentRoundStatusEnum,
} from '@spikemark/rest-api';

export function getTournamentDefaultUrl(tournament: ElasticTournament): string {
  const { eventType, tournamentId, hasResults } = tournament;

  const isPrePlay = !hasResults;
  const isMatchPlay =
    eventType === TournamentEventTypeEnum.MatchPlay ||
    eventType === TournamentEventTypeEnum.MedalMatchPlay;

  return `/tournaments/${tournamentId}/scoring/${
    isPrePlay ? 'participants' : isMatchPlay ? 'round' : 'team'
  }`;
}

export function isLiveTournament(
  tournament?: {
    tournamentIntegrationId?: TournamentRequestTournamentIntegrationIdEnum | string;
  } | null
): boolean {
  return Boolean(
    tournament &&
      'tournamentIntegrationId' in tournament &&
      tournament.tournamentIntegrationId ===
        TournamentRequestTournamentIntegrationIdEnum.Scoreboardlive
  );
}

export function isActiveLiveTournament(
  tournament?: {
    tournamentIntegrationId?: TournamentRequestTournamentIntegrationIdEnum | string;
    isComplete?: boolean;
  } | null
): boolean {
  return Boolean(isLiveTournament(tournament) && !tournament?.isComplete);
}

export function getRoundStatus(
  tournament: Pick<ElasticTournament, 'roundStatuses'>,
  roundId: number
): TournamentRoundStatusEnum {
  return (
    tournament.roundStatuses?.find((round) => round.roundId === String(roundId))?.status ?? 'ACTIVE'
  );
}
