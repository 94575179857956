'use client';

import { AdSize, ViewportSize } from '@/core/ad-config';
import { useGooglePublisherTags } from '@/providers/google-publisher-tags-provider';
import { AdvertisingTargetingMap } from '@/types/advertising';
import { useSize } from '@spikemark/shared-hooks';
import { If } from '@spikemark/ui/conditionals/if';
import { cn } from '@spikemark/ui/utils/cn';
import { FC, useId, useRef, useState } from 'react';
import { useAdSetup } from './use-ad-setup';
import { useAdTargeting } from './use-ad-targeting';
import { useRefreshAdSlot } from './use-refresh-ad-slot';
import { useStickyAdSlot } from './use-sticky-ad-slot';
export type AdSlotProps = {
  adUnitPath: string;
  sizeMappings: Array<{
    viewport: ViewportSize;
    sizes: AdSize[];
  }>;
  targeting?: AdvertisingTargetingMap;
  location?: string;
  className?: string;
  unitClassName?: string;
  sticky?: boolean;
};
const AdSlotBody: FC<AdSlotProps> = ({
  adUnitPath,
  sizeMappings,
  targeting,
  location,
  // TODO: Leave this here for now, but how should we handle location?
  className,
  unitClassName,
  sticky: stickyEnabled
}) => {
  const {
    resizeEnabled
  } = useGooglePublisherTags();
  const divId = useId();
  const adRef = useRef<HTMLDivElement>(null);
  const adSize = useSize(adRef);
  const [divMounted, setDivMounted] = useState(false);
  const [hideAnimationEnded, setHideAnimationEnded] = useState(false);
  const {
    slot
  } = useAdSetup({
    adUnitPath,
    divId,
    sizeMappings,
    enabled: divMounted
  });
  const {
    sticky
  } = useStickyAdSlot({
    enabled: Boolean(divMounted && stickyEnabled),
    timeoutAfterScroll: 1500
  });
  useAdTargeting({
    slot,
    targeting
  });
  useRefreshAdSlot({
    slot,
    enabled: divMounted,
    resizeEnabled
  });
  return <div className={cn('print:hidden', className)} style={adSize} data-sentry-component="AdSlotBody" data-sentry-source-file="ad-slot.tsx">
      <div ref={adRef} className={cn('text-center', {
      'ad-sticky': stickyEnabled && !hideAnimationEnded,
      'ad-sticky-hide': stickyEnabled && !sticky && !hideAnimationEnded
    })} onAnimationEnd={() => {
      setHideAnimationEnded(true);
    }}>
        <div id={divId} ref={() => setDivMounted(true)} className={cn('m-auto py-2 md:py-4 lg:py-6 inline-flex items-center empty:hidden box-content min-h-[50px] md:min-h-[90px]', unitClassName)} data-ad-unit-path={adUnitPath} data-size={JSON.stringify(sizeMappings)} data-targeting={JSON.stringify(targeting)}></div>
      </div>
    </div>;
};
export const AdSlot: FC<AdSlotProps> = props => {
  const {
    isAdUnitEnabled
  } = useGooglePublisherTags();
  return <If condition={isAdUnitEnabled(props.adUnitPath)} data-sentry-element="If" data-sentry-component="AdSlot" data-sentry-source-file="ad-slot.tsx">
      <AdSlotBody {...props} data-sentry-element="AdSlotBody" data-sentry-source-file="ad-slot.tsx" />
    </If>;
};