export const NoTournamentData = ({
  isLiveScored,
  customText
}: {
  isLiveScored?: boolean;
  customText?: string;
}) => <div className="p-8 text-sm lg:text-base text-secondary w-full text-center" data-sentry-component="NoTournamentData" data-sentry-source-file="no-tournament-data.tsx">
    {customText ?? <>
        <h3 className="text-lg lg:text-xl text-gray-950 font-bold mb-4">
          {isLiveScored ? 'This tournament is using ScoreboardLive.' : 'This tournament is not using ScoreboardLive.'}
        </h3>
        <p className="text-sm">
          {isLiveScored ? <>Results will appear once the tournament commences play.</> : <>Results will appear once the tournament host publishes scores.</>}
        </p>
      </>}
  </div>;