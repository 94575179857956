'use client';

import { AdSize, AdSlotDefinition, ViewportSize } from '@/core/ad-config';
import { FC } from 'react';
import { AdSlot } from './ad-slot';
import { AdSlotProps } from '@/components/ads/ad-slot';
import { OutOfPageAdSlot } from './out-of-page-ad-slot';
import { useGooglePublisherTags } from '@/providers/google-publisher-tags-provider';
const MobileViewport: ViewportSize = [0, 0];
const DesktopViewport: ViewportSize = [748, 0];
const LargeDesktopViewport: ViewportSize = [990, 0];
const BillboardSize: AdSize = [970, 250];
const LargeLeaderboardSize: AdSize = [970, 90];
const LeaderboardSize: AdSize = [728, 90];
const MobileLeaderboardSize: AdSize = [320, 50];
const MobileBannerSize: AdSize = [300, 50];
const InlineRectangleSize: AdSize = [300, 250];
const LargeMobileBannerSize: AdSize = [320, 100];
const LargeRectangleSize: AdSize = [336, 280];
const LeaderboardSizeMappings = [{
  viewport: MobileViewport,
  sizes: [LargeMobileBannerSize, MobileLeaderboardSize, MobileBannerSize]
}, {
  viewport: DesktopViewport,
  sizes: [LeaderboardSize]
}, {
  viewport: LargeDesktopViewport,
  sizes: [LargeLeaderboardSize, LeaderboardSize]
}];
const BillboardSizeMappings = [{
  viewport: MobileViewport,
  sizes: [LargeMobileBannerSize, MobileLeaderboardSize, InlineRectangleSize, MobileBannerSize, LargeRectangleSize]
}, {
  viewport: DesktopViewport,
  sizes: [LeaderboardSize]
}, {
  viewport: LargeDesktopViewport,
  sizes: [BillboardSize, LargeLeaderboardSize, LeaderboardSize]
}];
type AdProps = {
  definition: AdSlotDefinition;
} & Omit<AdSlotProps, 'adUnitPath' | 'sizeMappings'>;
export const LeaderboardAd: FC<AdProps> = ({
  definition,
  ...restProps
}) => {
  return <AdSlot adUnitPath={definition.path} unitClassName="min-h-[50px] md:min-h-[90px]" sizeMappings={LeaderboardSizeMappings} {...restProps} data-sentry-element="AdSlot" data-sentry-component="LeaderboardAd" data-sentry-source-file="ad-types.tsx" />;
};
export const BillboardAd: FC<AdProps> = ({
  definition,
  ...restProps
}) => {
  return <AdSlot adUnitPath={definition.path} sizeMappings={BillboardSizeMappings} {...restProps} data-sentry-element="AdSlot" data-sentry-component="BillboardAd" data-sentry-source-file="ad-types.tsx" />;
};
export const InterstitialAd: FC<AdProps> = ({
  definition,
  ...restProps
}) => {
  return <OutOfPageAdSlot adUnitPath={definition.path} format="interstitial" {...restProps} data-sentry-element="OutOfPageAdSlot" data-sentry-component="InterstitialAd" data-sentry-source-file="ad-types.tsx" />;
};
export const BottomAnchorAd: FC<AdProps> = ({
  definition,
  ...restProps
}) => {
  return <OutOfPageAdSlot adUnitPath={definition.path} format="bottom-anchor" {...restProps} data-sentry-element="OutOfPageAdSlot" data-sentry-component="BottomAnchorAd" data-sentry-source-file="ad-types.tsx" />;
};